:root {
  --vblue: #2d4e7d;
  --v80blue: #2d4e7d80;
  --v20blue: #2d4e7d20;
  --vshadowblue: #f4f4f4;
  --vgreen: #00a99e;
  --v20green: #00a99e80;
  --vshadowgreen: #00a99e80;
  --realwhitetext: #fff;

  --bgcolour1: #fff;
  --bgcolour2: #f3f3f4;
  --bgcolour3: #ededed;
  --bgcolour4: transparent;
  --bgcolour5: #f0f0f0;

  --bgform1: #fff;
  --bgscrollbar: #f3f4f9;

  --textcolor1: #676a6c;
  --textcolor2: #151515;
  --textcolor3: #2d4e7d;

  --buttoncolor1: #212529;

  --linkcolor1: #00574e;
  --linkcolor2: #00574e;
  --linkcolor3: #2d4e7d;

  --bgcard1: #fdfdfd;
  --bgcard2: #eef2f5;

  --imgopacity: 1;

  --primary: #2c4869;

  --warning: #f8ac59;

  --blue: #1c84c6;

  --danger: #B01A0C;
  --dangerhover: #6e1109;
  
  --success: #146090;
  --successhover: #0b4569;
  
  --live: #8bc34a;
  --livehover: #2D3E14;
  
  --draft: #ffb300;
  --drafthover: #4D3600;
}

@media (prefers-color-scheme: dark) {
  :root:not([data-theme='light']) {
    --bgcolour1: #2f4050;
    --bgcolour2: #626262;
    --bgcolour3: #1c3550;
    --bgcolour4: #2c4869;
    --bgcolour5: #626262;
    --bgform1: #fdfdfd;
    --bgscrollbar: #3c3c3c;
    --textcolor1: #fff;
    --textcolor2: #333;
    --textcolor3: #f8d8c4;
    --buttoncolor1: #fff;
    --linkcolor1: #accacc;
    --linkcolor2: #87b7bb;
    --linkcolor3: #b7531e;
    --bgcard1: #f1f1f1;
    --bgcard2: #fdfdfd;
    --imgopacity: 0.9;
  }
}

html[data-theme='dark'] {
  --bgcolour1: #3c3c3c;
  --bgcolour1: #2f4050;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h1 {
  font-size: 1.875rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  font-size: 0.75rem;
}
h6 {
  font-size: 0.625rem;
}
h3,
h4,
h5 {
  margin-top: 5px;
  font-weight: 600;
}
body {
  // font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: var(--bgcolour1);
  font-size: 0.8rem;
  font-size: clamp(0.8125rem, 2.5vw, 0.8125rem);
  color: var(--textcolor1);
  // overflow-x: hidden;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  // font-size: 0.75rem;
  // color: #515151;
}
html,
body {
  height: 100%;
}
body.full-height-layout #wrapper,
body.full-height-layout #page-wrapper {
  height: 100%;
}
/* Code */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 0.813rem;
  line-height: 1.42857143;
  color: var(--textcolor2);
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap; 
  background-color: #eff2f3;
  border: 1px solid #d1dade;
  border-radius: 2px;
}
code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
code {
  background-color: #F9F2F4;
  border-radius: 4px;
  color: #ca4440;
  font-size: 1rem;
  // padding: 2px 4px;
  white-space: nowrap;
}
video {
  width: 100% !important;
  height: auto !important;
}
a {
  cursor: pointer;
}
a:hover,
a:focus {
  text-decoration: none;
}
ul {
  list-style: none;
}

img {
  vertical-align: middle;
  border-style: none;
  display: inline-block;
  max-width: 100%;
  height: auto !important;
  opacity: var(--imgopacity);
  -ms-interpolation-mode: nearest-neighbor;
  -webkit-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

img,
img:hover,
img:active {
  opacity: 1;
}

.docs pre code {
  white-space: pre-wrap;
}

a:not([href]):not([tabindex]) {
  color: var(--linkcolor1);
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, 
  a:not([href]):not([tabindex]):active {
  color: var(--linkcolor2);
  text-decoration: none;
}