.loader {
    display: inline-block;
    width: 64px;
    height: 64px;
}
.loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #177bbb;
    border-color: #177bbb transparent #177bbb transparent;
    animation: loader 1.2s linear infinite;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-parent .h3 {
    color: var(--textcolor1);
    margin-top: 10px;
    margin-bottom: 10px;
}

.loader-lg {
    display: inline-block;
    width: 96px;
    height: 96px;
}
.loader-lg:after {
    content: " ";
    display: block;
    width: 96px;
    height: 96px;
    margin: 1px;
    border-radius: 50%;
    border: 9px solid #177bbb;
    border-color: #177bbb transparent #177bbb transparent;
    animation: loader 1.2s linear infinite;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-sm {
    display: inline-block;
    width: 32px;
    height: 32px;
}
.loader-sm:after {
    content: " ";
    display: block;
    width: 32px;
    height: 32px;
    margin: 1px;
    border-radius: 50%;
    border: 3px solid #177bbb;
    border-color: #177bbb transparent #177bbb transparent;
    animation: loader 1.2s linear infinite;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
