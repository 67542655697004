.text-light,
a.text-light:hover,
a.text-light:focus {
    color: #fff;
}

.text-muted,
a.text-muted:hover,
a.text-muted:focus {
    color: #909ba1;
}

.text-primary,
a.text-primary:hover,
a.text-primary:focus {
    color: #1c3550;
}

.text-info,
a.text-info:hover,
a.text-info:focus {
    color: #0391d1;
}

.text-success,
a.text-success:hover,
a.text-success:focus {
    color: #79af3a;
}

.text-warning,
a.text-warning:hover,
a.text-warning:focus {
    color: #db9a00;
}

.text-danger,
a.text-danger:hover,
a.text-danger:focus {
    color: #f22314;
}

.text-main,
a.text-main:hover,
a.text-main:focus {
    color: #4d627b;
}

.text-mint,
a.text-mint:hover,
a.text-mint:focus {
    color: #1f897f;
}

.text-purple,
a.text-purple:hover,
a.text-purple:focus {
    color: #953ca4;
}

.text-pink,
a.text-pink:hover,
a.text-pink:focus {
    color: #ed417b;
}

.text-dark,
a.text-dark:hover,
a.text-dark:focus {
    color: #2b323a;
}