.p-xxs {
  padding: 5px;
}
.p-xs {
  padding: 10px;
}
.p-sm {
  padding: 15px;
}
.p-m {
  padding: 20px;
}
.p-md {
  padding: 25px;
}
.p-lg {
  padding: 30px;
}
.p-xl {
  padding: 40px;
}
.p-w-xs {
  padding: 0 10px;
}
.p-w-sm {
  padding: 0 15px;
}
.p-w-m {
  padding: 0 20px;
}
.p-w-md {
  padding: 0 25px;
}
.p-w-lg {
  padding: 0 30px;
}
.p-w-xl {
  padding: 0 40px;
}
.p-h-xs {
  padding: 10px 0;
}
.p-h-sm {
  padding: 15px 0;
}
.p-h-m {
  padding: 20px 0;
}
.p-h-md {
  padding: 25px 0;
}
.p-h-lg {
  padding: 30px 0;
}
.p-h-xl {
  padding: 40px 0;
}
.no-padding {
  padding: 0 !important;
}
.pad-no {
  padding: 0 !important
}

.pad-all {
  padding: 15px
}

.pad-top {
  padding-top: 15px
}

.pad-btm {
  padding-bottom: 15px
}

.pad-lft {
  padding-left: 15px
}

.pad-rgt {
  padding-right: 15px
}

.pad-hor {
  padding-left: 15px;
  padding-right: 15px
}

.pad-ver {
  padding-top: 15px;
  padding-bottom: 15px
}