.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
}

.vertical-align div {
  display: inline-block;
  vertical-align: middle;
}
.vertical-align h2,
.vertical-align h3 {
  margin: 0;
}

.block {
  display: block;
}
.clear {
  display: block;
  overflow: hidden;
}

.nl2br {
  white-space: pre-line;
}

.number-circle {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid;
}

.number-circle:before,
.number-circle:after {
  content: '\200B';
  display: inline-block;
  line-height: 0px;
  padding-top: 50%;
  padding-bottom: 50%;
}

.number-circle:before {
  padding-left: 8px;
}

.number-circle:after {
  padding-right: 8px;
}