.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
  border-radius:6px;
}
.ibox.collapsed .ibox-content {
  display: none;
}
.ibox.collapsed .fa.fa-chevron-up:before {
  content: "\f078";
}
.ibox.collapsed .fa.fa-chevron-down:before {
  content: "\f077";
}
.ibox:after,
.ibox:before {
  display: table;
}
.ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 15px 90px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
}
.ibox-content {
  background-color: var(--bgcolour1);
  color: var(--textcolor1);
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}
.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.ibox-content {
  clear: both;
}
.ibox-heading {
  background-color: #f3f6fb;
  border-bottom: none;
}
.ibox-heading h3 {
  font-weight: 200;
  font-size: 24px;
}
.ibox-title h5 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 7px;
  padding: 0;
  text-overflow: ellipsis;
  float: none;
}
.ibox-title .label {
  margin-left: 4px;
}
.ibox-title .pull-right {
  position: absolute;
  right: 15px;
  top: 15px;
}
.ibox-tools {
  display: block;
  float: none;
  margin-top: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
  text-align: right;
}
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #c4c4c4 !important;
}
.ibox-tools a.btn-primary {
  color: #fff !important;
}
.ibox-tools .dropdown-menu > li > a {
  padding: 4px 10px;
  font-size: 12px;
  color: #676a6c !important;
}
.ibox .ibox-tools.open > .dropdown-menu {
  left: auto;
  right: 0;
}
.ibox-tools .dropdown-toggle::after {
  display: none;
}
.ibox-content.text-box {
  padding-bottom: 0;
  padding-top: 15px;
}

.fullscreen-ibox-mode .animated {
  animation: none;
}
body.fullscreen-ibox-mode {
  overflow-y: hidden;
}
.ibox.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0;
}
.ibox.fullscreen .collapse-link {
  display: none;
}
.ibox.fullscreen .ibox-content {
  min-height: calc(100% - 48px);
}