/* DATATABLES */
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}
.dataTables_wrapper {
  padding-bottom: 30px;
}
.dataTables_length {
  float: left;
}
.dataTables_filter label {
  margin-right: 5px;
}

.th {
  background-color: rgba(0,0,0,.05);
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}