
.w-150 {
  min-width: 150px;
}
.w-200 {
  min-width: 200px;
}
.w-300 {
  min-width: 300px;
}
.full-width {
  width: 100% !important;
}
.half-width {
  width: 50% !important;
}