
.font-bold {
  font-weight: 600;
}
.font-normal {
  font-weight: 400;
}
.font-default {
  font-weight: 100;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-90 {
  font-size: 90%;
}
.text-80 {
  font-size: 80%;
}
.text-70 {
  font-size: 70%;
}
.font-italic {
  font-style: italic;
}
.font-vertical-align-middle {
  line-height: inherit;
  vertical-align: middle;
}
.pointer {
  cursor: pointer;
}

.fw-thin {
  font-weight: 100 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-regular {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 600 !important;
}

.fs-4 {
  font-size: 4px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.fs-70 {
  font-size: 70px !important;
}

.fs-80 {
  font-size: 80px !important;
}

.fs-90 {
  font-size: 90px !important;
}

.fs-100 {
  font-size: 100px !important;
}

.fs-120 {
  font-size: 120px !important;
}

.fs-150 {
  font-size: 150px !important;
}

.fs-170 {
  font-size: 170px !important;
}

.fs-200 {
  font-size: 200px !important;
}