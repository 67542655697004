/* WIDGETS */
.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;

  & > .superflex {
    display: flex;
    flex-direction: row;

    @media screen and (min-width: 768px) and (max-width: 1399px) {
      flex-direction: column;
      align-items: center;
    }

    & > .item:first-child {
      flex-grow: 2;
      flex-basis: 174px;
      flex-shrink: 1;
      text-align: left;

      @media screen and (min-width: 768px) and (max-width: 1399px) {
        flex-basis: 80px;
      }
    }

    & > .item:last-child {
      flex-grow: 1;
      flex-basis: 150px;
      flex-shrink: 0;
      text-align: right;
      @media screen and (min-width: 768px) and (max-width: 1399px) {
        flex-basis: 80px;
        text-align: center;
      }
    }

    
  }
  
}
.widget.style1 h2, .widget.style1 .h2 {
  font-size: 30px;
}
.widget h2,
.widget h3,
.widget .h2,
.widget .h3 {
  margin-top: 5px;
  margin-bottom: 0;
}
.widget-text-box {
  padding: 20px;
  border: 1px solid #e7eaec;
  background: #ffffff;
}
.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}
.widget .flot-chart {
  height: 100px;
}



.widget-header {
  padding: 15px 15px 50px 15px;
  min-height: 125px;
  position: relative;
  overflow: hidden
}

.widget-control {
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0
}

.widget-bg {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%
}

.widget-title {
  position: relative
}

.widget-body {
  padding: 25px 50px 15px 15px;
  position: relative
}

.widget-img {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  margin-left: -32px;
  top: -32px
}

.li {
  list-style: none;
}
