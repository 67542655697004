.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
  border-radius: 0.25em;
  line-height: 1;
  white-space: nowrap;
}
.nav .label,
.ibox .label {
  font-size: 10px;
}
.badge {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: 600;
  padding-bottom: 4px;
  padding-left: 6px;
  padding-right: 6px;
  text-shadow: none;
  white-space: nowrap;
}
.label-primary,
.badge-primary {
  background-color: #2c4869;
  color: #FFFFFF;
}
.label-navy,
.badge-navy {
  background-color: #1ab394;
  color: #FFFFFF;
}
.label-success,
.badge-success {
  background-color: #1c84c6;
  color: #FFFFFF;
}
.label-warning,
.badge-warning {
  background-color: #f8ac59;
  color: #FFFFFF;
}
.label-warning-light,
.badge-warning-light {
  background-color: #f8ac59;
  color: #ffffff;
}
.label-danger,
.badge-danger {
  background-color: #ed5565;
  color: #FFFFFF;
}
.label-info,
.badge-info {
  background-color: #23c6c8;
  color: #FFFFFF;
}
.label-inverse,
.badge-inverse {
  background-color: #262626;
  color: #FFFFFF;
}
.badge-disable {
  background-color: #FFFFFF;
  color: #5E5E5E;
}
.label-white,
.badge-disable {
  background-color: #2A2E36;
  color: #8B91A0;
}