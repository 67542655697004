.list-has-link li:before {
    display: none;
  }
  
.list-icon.list-arrow li:before, .list-icon.list-arrow a:before {
  content: "\f054";
}

.list-icon.list-check li:before, .list-icon.list-check a:before {
  content: "\f00c";
}

.list-icon.list-close li:before, .list-icon.list-close a:before {
  content: "\f00d";
}

.list-icon.list-bullet li:before, .list-icon.list-bullet a:before {
  content: "\f192";
}

.list-icon.list-star li:before, .list-icon.list-star a:before {
  content: "\f005";
}

.list-icon.list-dollar li:before, .list-icon.list-dollar a:before {
  content: "\f155";
}

.list-secondary li:before, .list-secondary a:before {
  color: #bbbbbe;
}

.list-primary li:before, .list-primary a:before {
  color: #0b50d8;
}

.list-success li:before, .list-success a:before {
  color: #60aa08;
}

.list-info li:before, .list-info a:before {
  color: #0d91ee;
}

.list-warning li:before, .list-warning a:before {
  color: #faa80b;
}

.list-danger li:before, .list-danger a:before {
  color: #df290b;
}

.list-media > .list-item {
  display: block;
}

.list-media .media-img {
  position: relative;
  float: left;
}

.list-media .media-img img {
  line-height: 45px;
  height: 45px;
  width: 45px;
  text-align: center;
}

.list-media .info {
  padding-left: 60px;
  min-height: 45px;
  height: auto;
  position: relative;
}

.list-media .info .sub-title {
  display: block;
  font-size: 0.75rem;
}

.list-media-content {
  position: relative;
  float: left;
  font-size: 0.875rem;
  line-height: 47px;
  height: 45px;
  width: 45px;
  text-align: center;
  border-radius: 50%;
  margin-right: 6px;
}

.list-media .info .list-checkbox {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-28px);
  -webkit-transform: translateY(-28px);
  -moz-transform: translateY(-28px);
  -o-transform: translateY(-28px);
  -ms-transform: translateY(-28px);
}

.list-media-secondary {
  color: #555555;
  background-color: #bbbbbe;
}

.list-media-primary {
  color: #fff;
  background-color: #0b50d8;
}

.list-media-success {
  color: #fff;
  background-color: #60aa08;
}

.list-media-info {
  color: #fff;
  background-color: #0d91ee;
}

.list-media-warning {
  color: #fff;
  background-color: #faa80b;
}

.list-media-danger {
  color: #fff;
  background-color: #df290b;
}

.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.list-group-item:last-child {
  margin-bottom: 0 !important;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

a.list-group-item:last-child {
  margin-bottom: -1px !important;
}

.list-group-item {
  background-color: #fff;
  margin-bottom: -1px !important;
  padding: 1rem 1.25rem;
  border: 1px solid #f1f2f3;
}

.list-group-item-primary {
  color: #fff !important;
  background-color: #0b50d8 !important;
}

.list-group-item-primary:hover {
  color: #fff !important;
  background-color: #093c8e !important;
}

.list-group-item-secondary {
  color: #555555 !important;
  background-color: #bbbbbe !important;
}

.list-group-item-secondary:hover {
  color: #555555 !important;
  background-color: #919194 !important;
}

.list-group-item-success {
  color: #fff !important;
  background-color: #60aa08 !important;
}

.list-group-item-success:hover {
  color: #fff !important;
  background-color: #518203 !important;
}

.list-group-item-info {
  color: #fff !important;
  background-color: #0d91ee !important;
}

.list-group-item-info:hover {
  color: #fff !important;
  background-color: #095a9e !important;
}

.list-group-item-warning {
  color: #fff !important;
  background-color: #faa80b !important;
}

.list-group-item-warning:hover {
  color: #fff !important;
  background-color: #d78400 !important;
}

.list-group-item-danger {
  color: #fff !important;
  background-color: #df290b !important;
}

.list-group-item-danger:hover {
  color: #fff !important;
  background-color: #a22204 !important;
}

.list-group-item-light {
  color: #555555 !important;
  background-color: #f1f2f3 !important;
}

.list-group-item-light:hover {
  color: #555555 !important;
  background-color: #e7e8e9 !important;
}

.list-group-item-dark {
  color: #fff !important;
  background-color: #515254 !important;
}

.list-group-item-dark:hover {
  color: #fff !important;
  background-color: #303132 !important;
}

.list-icon .list-group-item {
  padding-left: 3rem !important;
}

.list-icon li.list-group-item:before, .list-icon a.list-group-item:before {
  top: 1.325rem;
  left: 1.5rem;
}

.list-group-item-action {
  color: #555555;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  background-color: #bbbbbe;
}

/*--- topics list group ---*/
.topics-list {
  border-radius: 0.5rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
}

.topics-list .list-header a {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.topics-list a.list-group-item {
  margin-bottom: 0 !important;
}

.topics-list .topic-list-item:last-child a.list-group-item {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.topics-list .list-group-item {
  padding: 1.75rem;
  border: none;
  border-bottom: 1px solid #f1f2f3;
}

.topics-list .list-header .list-group-item {
  padding: 1.5rem 1.75rem;
}

.topics-list .list-group-item-action:focus, .topics-list .list-group-item-action:hover {
  background-color: rgba(250, 250, 250, 0.25);
}

.topics-list .list-header a {
  background-color: rgba(250, 250, 250, 0.75);
  pointer-events: none;
}

.topics-list .title {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.topics-list a:hover .title {
  color: #0b50d8;
}

  /*------- promo box -------*/
.promo-box {
  background-color: #fff;
  margin-bottom: 2rem;
  padding: 2.5rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.05);
}

.main-container .promo-box:last-child {
  margin-bottom: 0;
}

.promo-box.dark, .dark .promo-box {
  background: #303132;
}

.promo-container {
  position: relative;
}

.promo-icon {
  font-size: 40px;
  border-radius: 0.5rem;
  margin-top: -10px;
}

.promo-icon.bi {
  margin-top: -15px;
}

.promo-icon-bg {
  text-align: center;
  width: 80px;
  height: 80px;
  border: 1px solid transparent;
  border-radius: 8px;
  position: relative;
}

.promo-icon-bg i, .promo-icon-bg .text-icon {
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  margin-top: -0.725rem;
  left: 0;
  right: 0;
}

.promo-icon-bg.icon-md {
  width: 65px;
  height: 65px;
  border: 1px solid transparent;
}

.promo-icon-bg.icon-md i, .promo-icon-bg.icon-md .text-icon {
  font-size: 1.25rem;
  position: absolute;
  top: 50%;
  margin-top: -0.625rem;
  left: 0;
  right: 0;
}

.promo-icon-bg.icon-sm {
  width: 50px;
  height: 50px;
  border: 1px solid transparent;
}

.promo-icon-bg.icon-sm i, .promo-icon-bg.icon-sm .text-icon {
  font-size: 1.1rem;
  position: absolute;
  top: 50%;
  margin-top: -0.55rem;
  left: 0;
  right: 0;
}

.promo-block {
  margin: 30px 0;
}

.promo-box-image {
  max-width: 80px;
  border-radius: 8px;
}

.promo-box-image.img-md {
  max-width: 65px;
}

.promo-box-image.img-sm {
  max-width: 50px;
}