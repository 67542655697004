
a {
  color: white;
}

a:hover {
  color: white;
}

.container-fluid-bg {
  background-color: var(--bgcolour1);
  position: relative;
  z-index: 100;
  height: 100%;
}

.ibox {
  width: clamp(300px, 40em, 90vw)
}

.middle-box {
  display: flex;
  font-size: 1rem;
  position: relative;
  max-width: 750px;
  z-index: 100;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-direction: column;
  align-items: center;
  color: var(--textcolor2)
}

.middle-box h1 {
  font-size: 1.5rem;
}

.wrapper .middle-box {
  margin-top: 140px;
}

#bg-overlay {
  position: absolute;
  overflow: hidden;
  padding: 0px;
  min-height: 100vh;
  min-width: 100vw
}

#bg-overlay img {
  position: absolute;
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  opacity: 0.01;
    margin: auto; 
  min-width: 50%;
  min-height: 50%;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  transition: opacity 1.5s ease-in;
}

.container-login .form-control {
  max-width: calc(100% - 40px);
  font-size: 0.875rem;
  height: 100%;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #e9e9e9;
  transition: all 0.1s fade-in;
  padding: 6px 6px;
}

.container-login .form-control:focus {
  border-color: #54abd9;
  box-shadow: none;
  transition: all .5s ease-in-out;
}

.container-login .form-checkbox.active.form-icon::after {
  content: "\f14a";
}

.container-login .form-checkbox.form-icon::after,
.container-login .form-radio.form-icon::after {
  content: "\f0c8";
  color: inherit;
  font-size: 19px;
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  left: .1em;
  top: 40%;
  margin-top: -0.44em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-login .form-checkbox:not(.btn),
.container-login .form-radio:not(.btn) {
  display: inline-block;
  background-color: transparent;
  border: 0;
  position: relative;
  padding: 3px;
  padding-left: 3px;
  line-height: 1em;
  min-width: 19px;
  margin: 0;
  cursor: pointer;
}

.container-login .form-checkbox>input[type="checkbox"],
.container-login .form-radio>input[type="radio"] {
  opacity: 0;
  /*position: absolute !important;*/
  visibility: hidden;
  z-index: -1;
}

.container-login .middle-box .alert-danger {
  background-color: var(--danger);
  border-color: transparent;
  border-left: 3px solid #cf1c0f;
  color: #fff;
}

.container-login > input + span {
  margin-left: 1em;
}

.helper {
  background: rgba( 63, 56, 177, 0.7 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 9.5px );
-webkit-backdrop-filter: blur( 9.5px );
border-radius: 4px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
padding: 6px 16px;
margin: 10px 10px;
max-width: 200px;
transition: all .3s ease-in-out;
}

.helper:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.helper:focus,
.helper:hover {
    background-color:rgba( 63, 56, 177, 1 );
    color: #fff !important;
    cursor: pointer;
}

.justify-content-center {
  justify-content: center;
  display: flex;
}

@media (min-width: 648px) {
  .md-start {
    justify-content: start!important;
  }

  .md-end {
    justify-content: end!important;
  }
}