/* PANELS */
.panel-title > .small,
.panel-title > .small > a,
.panel-title > a,
.panel-title > small,
.panel-title > small > a {
  color: inherit;
}
.page-heading {
  border-top: 0;
  padding: 0 10px 20px 10px;
}
.panel-heading h1,
.panel-heading h2 {
  margin-bottom: 5px;
}
.panel-body {
  padding: 15px;
}
/* Bootstrap 3.3.x panels */
.panel {
  margin-bottom: 20px;
  background-color: var(--bgcolour1);
  border: 1px solid transparent;
  border-radius: 4px;
}
.panel-heading {
  color: white;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.panel-footer {
  padding: 10px 15px;
  border-top: 1px solid #e7eaec;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #e7eaec;
}
.panel-default {
  border-color: #e7eaec;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.panel-primary {
  border-color: #2c4869;
}
.panel-primary > .panel-heading {
  background-color: #2c4869;
  border-color: #2c4869;
}
.panel-success {
  border-color: #1c84c6;
}
.panel-success > .panel-heading {
  background-color: #1c84c6;
  border-color: #1c84c6;
  color: #ffffff;
}
.panel-info {
  border-color: #23c6c8;
}
.panel-info > .panel-heading {
  background-color: #23c6c8;
  border-color: #23c6c8;
  color: #ffffff;
}
.panel-warning {
  border-color: #f8ac59;
}
.panel-warning > .panel-heading {
  background-color: #f8ac59;
  border-color: #f8ac59;
  color: #ffffff;
}
.panel-danger {
  border-color: #ed5565;
}
.panel-danger > .panel-heading {
  background-color: #ed5565;
  border-color: #ed5565;
  color: #ffffff;
}
.progress-bar {
  background-color: #2c4869;
}
.progress-small,
.progress-small .progress-bar {
  height: 10px;
}
.progress-small,
.progress-mini {
  margin-top: 5px;
}
.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}
.progress-bar-navy-light {
  background-color: #3dc7ab;
}
.progress-bar-success {
  background-color: #1c84c6;
}
.progress-bar-info {
  background-color: #23c6c8;
}
.progress-bar-warning {
  background-color: #f8ac59;
}
.progress-bar-danger {
  background-color: #ed5565;
}
.panel-title {
  font-size: inherit;
}
.panel.blank-panel {
  background: none;
  margin: 0;
}
.blank-panel .panel-heading {
  padding-bottom: 0;
}




.panel-primary .panel-heading,
.panel-primary .panel-footer,
.panel-primary.panel-colorful {
    background-color: #25476a;
    border-color: #25476a;
    color: #fff
}

.panel-primary.panel-colorful {
    box-shadow: 0 1px 1px #0b141e
}

.panel-info .panel-heading,
.panel-info .panel-footer,
.panel-info.panel-colorful {
    background-color: #027DB6;
    border-color: #027DB6;
    color: #fff
}

.panel-info.panel-colorful {
    box-shadow: 0 1px 1px #02638f
}

.panel-success .panel-heading,
.panel-success .panel-footer,
.panel-success.panel-colorful {
    background-color: #5A822B;
    border-color: #5A822B;
    color: #fff
}

.panel-success.panel-colorful {
    box-shadow: 0 1px 1px #577d2a
}

.panel-warning .panel-heading,
.panel-warning .panel-footer,
.panel-warning.panel-colorful {
    background-color: #ffb300;
    border-color: #ffb300;
    color: #fff
}

.panel-warning.panel-colorful {
    box-shadow: 0 1px 1px #996b00
}

.panel-danger .panel-heading,
.panel-danger .panel-footer,
.panel-danger.panel-colorful {
    background-color: #f44336;
    border-color: #f44336;
    color: #fff
}

.panel-danger.panel-colorful {
    box-shadow: 0 1px 1px #ba160a
}

.panel-mint .panel-heading,
.panel-mint .panel-footer,
.panel-mint.panel-colorful {
    background-color: #1C7D73;
    border-color: #1C7D73;
    color: #fff
}

.panel-mint.panel-colorful {
    box-shadow: 0 1px 1px #13534d
}

.panel-purple .panel-heading,
.panel-purple .panel-footer,
.panel-purple.panel-colorful {
    background-color: #ab47bc;
    border-color: #ab47bc;
    color: #fff
}

.panel-purple.panel-colorful {
    box-shadow: 0 1px 1px #682a73
}

.panel-pink .panel-heading,
.panel-pink .panel-footer,
.panel-pink.panel-colorful {
    background-color: #f06292;
    border-color: #f06292;
    color: #fff
}

.panel-pink.panel-colorful {
    box-shadow: 0 1px 1px #d71556
}

.panel-dark .panel-heading,
.panel-dark .panel-footer,
.panel-dark.panel-colorful {
    background-color: #3a444e;
    border-color: #3a444e;
    color: #fff
}

.panel-dark.panel-colorful {
    box-shadow: 0 1px 1px #0f1114
}

.panel-colorful>.panel-heading {
    border: 0
}
