.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.no-pointer-events {
    pointer-events: none;
}
