/* FAQ */
.faq-item {
  padding: 20px;
  margin-bottom: 2px;
  background: #fff;
}
.faq-question {
  font-size: 18px;
  font-weight: 600;
  color: #2c4869;
  display: block;
}
.faq-question:hover {
  color: #179d82;
}
.faq-answer {
  margin-top: 10px;
  background: #f3f3f4;
  border: 1px solid #e7eaec;
  border-radius: 3px;
  padding: 15px;
}
.faq-item .tag-item {
  background: #f3f3f4;
  padding: 2px 6px;
  font-size: 10px;
  text-transform: uppercase;
}