body.modal-open {
  padding-right: inherit !important;
}
_::-webkit-full-page-media,
_:future,
:root body.modal-open .wrapper-content.animated {
  -webkit-animation: none;
  -ms-animation-nam: none;
  animation: none;
}
body.modal-open .animated {
  animation-fill-mode: initial;
  z-index: inherit;
}

.modal-content {
  background-clip: padding-box;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  outline: 0 none;
  position: relative;
}
.modal-dialog {
  z-index: 2200;
}
.modal-body {
  padding: 20px 30px 30px 30px;
}
.inmodal .modal-body {
  background: #f8fafb;
}
.inmodal .modal-header {
  padding: 30px 15px;
  text-align: center;
  display: block;
}
.animated.modal.fade .modal-dialog {
  -webkit-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.inmodal .modal-title {
  font-size: 26px;
}
.inmodal .modal-icon {
  font-size: 84px;
  color: #e2e3e3;
}
.modal-footer {
  margin-top: 0;
}
.modal-backdrop {
  z-index: 2040 !important;
}
.modal {
  z-index: 2050 !important;
}