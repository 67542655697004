.img-sm {
  width: 32px;
  height: 32px;
}
.img-md {
  width: 64px;
  height: 64px;
}
.img-lg {
  width: 96px;
  height: 96px;
}

/* CIRCLE */
.img-circle {
  border-radius: 50%;
}

/* Image cropper style */
.img-container,
.img-preview {
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.img-preview-sm {
  height: 130px;
  width: 200px;
}
.img-shadow {
  -webkit-box-shadow: 0 0 3px 0 #919191;
  -moz-box-shadow: 0 0 3px 0 #919191;
  box-shadow: 0 0 3px 0 #919191;
}

img.circle-border {
  border: 6px solid #FFFFFF;
  border-radius: 50%;
}