@import "fonts";
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css";
@import "default";

@import "branding";
@import "components/loader";

@import "components/animations";

@import "components/buttons";
@import "misc";
@import "components/heights";
@import "components/widths";
@import "components/widgets";
@import "components/breadcrumbs";
@import "components/panels";
@import "components/hr";
@import "components/card";


@import "components/navbar";
@import "components/wrappers";
@import "components/backgrounds";
@import "components/margins";
@import "components/paddings";
@import "components/borders";
@import "components/ibox";
@import "components/alerts";

@import "components/badges_and_labels";
@import "v2port/labels";
@import "v2port/card";

@import "v2port/text-colors";
@import "components/text-colors";

@import "components/tables";
@import "components/datatables";

@import "components/cursors";

@import "components/media-queries-all";
@import "components/images";
@import "components/profile";
@import "components/sidebar";
@import "components/dashboard";
@import "components/font-styles";
@import "components/forms";
@import "components/forum";
@import "components/faq";
@import "components/ecommerce";
@import "components/file-manager";
@import "components/list-groups";

@import "components/clients-list";
@import "components/projects";
@import "components/issue-tracker";
@import "components/vertical-container";
@import "components/modals";

@import "components/support";
@import "components/social-feed";

@import "components/templates";
@import "components/activity-stream";
@import "components/query-playground";

@import "prism";

@import "components/external/nanoscroller";
@import "components/external/jquery.steps";

@import "components/react/react-contexify";
@import "components/react/sticky-table-table.scss";
@import "components/react/react-select.scss";
@import "components/react/react-tagsinput.scss";
@import "components/react/reactflow.scss";

@import "components/legacy/old_magic_form.scss";