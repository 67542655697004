/* INPUTS */
.inline {
  display: inline !important;
}
.inline-block {
  display: inline-block !important;
}
.input-s-sm {
  width: 120px;
}
.input-s {
  width: 200px;
}
.form-control {
  font-size: 0.9rem;
}
select.form-control:not([size]):not([multiple]) {
  height: 2.05rem;
}
.input-sm,
.form-control-sm {
  height: 31px;
  font-size: 0.75rem;
}
.input-s-lg {
  width: 250px;
}
.i-checks {
  padding-left: 0;
}
.form-control,
.single-line {
  background-color: var(--bgform1);
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: var(--textcolor2);
  display: block;
  padding: 6px 12px;
  transition: border-color 0.35s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.form-control:active,
.single-line:active {
  transition: border-color 0.35s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.form-control:focus,
.single-line:focus {
  border-color: #2c4869;
  transition: border-color 0.35s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.has-success .form-control,
.has-success .form-control:focus {
  border-color: #2c4869;
}
.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: #f8ac59;
}
.has-error .form-control,
.has-error .form-control:focus {
  border-color: #ed5565;
}
.has-success .control-label {
  color: #2c4869;
}
.has-warning .control-label {
  color: #f8ac59;
}
.has-error .control-label {
  color: #ed5565;
}
.input-group-addon {
  background-color: var(--bgform1);
  border: 1px solid #E5E6E7;
  border-radius: 1px;
  color: var(--textcolor2);
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 9px 12px 4px 12px;
  text-align: center;
}
.spinner-buttons.input-group-btn .btn-xs {
  line-height: 1.13;
}
.spinner-buttons.input-group-btn {
  width: 20%;
}
.noUi-connect {
  background: none repeat scroll 0 0 #2c4869;
  box-shadow: none;
}
.slider_red .noUi-connect {
  background: none repeat scroll 0 0 #ed5565;
  box-shadow: none;
}

/* Validation */
label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}
.form-control.error {
  border: 1px dotted #cc5965;
}


.search {
  height: 46px;
  font-size: 17px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.07);
  transition-duration: .5s
}

.search:focus {
  border-color: #25476a;
  box-shadow: none;
  transition-duration: .5s
}

.search:focus-feedback {
  z-index: 10
}

.invalid-feedback {
  margin-left: 45px;
  font-size: 0.875rem;
}
.custom-file-label {
  border-radius: 0;
  border: 0;
  color: var(--textcolor1);
  font-size: 0.8rem;
}
.custom-file-label.disabled {
  background-color: #e9ecef;
}
.custom-file-label.empty {
  color: rgb(103, 106, 108);
}
.col-form-label {
  font-size: 0.8rem;
}

input.custom-file {
  font-size: 0.5em;
}
.form-text {
  font-size: 0.8rem;
}