.card-body {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 0.25rem;
}

.bg-none > .card {
    background: none;
    border: none;
}