button:focus {
  outline: 0 !important;
}
.btn {
  border-radius: 3px;
  font-size: 0.8rem;
  color: var(--buttoncolor1);
}
.btn:focus {
  box-shadow: none;
}
.btn-xs {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: .8rem;
}
.float-e-margins .btn {
  margin-bottom: 5px;
}
.btn-w-m {
  min-width: 120px;
}
.btn-primary.btn-outline {
  color: #2c4869;
}
.btn-success.btn-outline {
  color: #1c84c6;
}
.btn-info.btn-outline {
  color: #23c6c8;
}
.btn-warning.btn-outline {
  color: #f8ac59;
}
.btn-danger.btn-outline {
  color: #ed5565;
}
.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-primary {
  color: #fff;
  background-color: #2c4869;
  border-color: #2c4869;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #1c3550 !important;
    border-color: #1c3550 !important;
    color: #fff !important
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #1c3550;
  border-color: #1c3550;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1c3550;
  border-color: #1c3550;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-success {
  color: #fff;
  background-color: var(--success);
  border-color: var(--success);
}
.btn-success:hover,
.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: var(--successhover);
  border-color: var(--successhover);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: grey;
  border-color: var(--success);
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--successhover);
  border-color: var(--successhover);
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-info {
  color: #fff;
  background-color: #23c6c8;
  border-color: #23c6c8;
}
.btn-info:hover,
.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #21b9bb;
  border-color: #21b9bb;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-default {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  color: #cacaca;
}
.btn-warning {
  color: #ffffff;
  background-color: #f8ac59;
  border-color: #f8ac59;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #f7a54a;
  border-color: #f7a54a;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-danger {
  color: #fff;
  background-color: var(--danger);
  border-color: var(--danger);
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: var(--dangerhover);
  border-color: var(--dangerhover);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: var(--dangerhover);
  border-color: var(--dangerhover);
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: var(--dangerhover);
  border-color: var(--dangerhover);
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-link {
  color: inherit;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  color: #2c4869;
  text-decoration: none;
}
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
  background-image: none;
  box-shadow: none;
}
.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
  color: #cacaca;
}
.btn-white {
  color: inherit;
  background: white;
  border: 1px solid #e7eaec;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
  color: inherit;
  border: 1px solid #d2d2d2;
}
.btn-white:active,
.btn-white.active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-image: none;
}
.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
  color: #cacaca;
}

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s;
}
.btn-rounded {
  border-radius: 50px;
}
.btn-large-dim {
  width: 90px;
  height: 90px;
  font-size: 42px;
}
button.dim {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 6px;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  margin-bottom: 20px !important;
}
button.dim:active {
  top: 3px;
}
button.btn-primary.dim {
  box-shadow: inset 0 0 0 #16987e, 0 5px 0 0 #16987e, 0 10px 5px #999999 !important;
}
button.btn-primary.dim:active {
  box-shadow: inset 0 0 0 #16987e, 0 2px 0 0 #16987e, 0 5px 3px #999999 !important;
}
button.btn-default.dim {
  box-shadow: inset 0 0 0 #b3b3b3, 0 5px 0 0 #b3b3b3, 0 10px 5px #999999 !important;
}
button.btn-default.dim:active {
  box-shadow: inset 0 0 0 #b3b3b3, 0 2px 0 0 #b3b3b3, 0 5px 3px #999999 !important;
}
button.btn-warning.dim {
  box-shadow: inset 0 0 0 #f79d3c, 0 5px 0 0 #f79d3c, 0 10px 5px #999999 !important;
}
button.btn-warning.dim:active {
  box-shadow: inset 0 0 0 #f79d3c, 0 2px 0 0 #f79d3c, 0 5px 3px #999999 !important;
}
button.btn-info.dim {
  box-shadow: inset 0 0 0 #1eacae, 0 5px 0 0 #1eacae, 0 10px 5px #999999 !important;
}
button.btn-info.dim:active {
  box-shadow: inset 0 0 0 #1eacae, 0 2px 0 0 #1eacae, 0 5px 3px #999999 !important;
}
button.btn-success.dim {
  box-shadow: inset 0 0 0 #1872ab, 0 5px 0 0 #1872ab, 0 10px 5px #999999 !important;
}
button.btn-success.dim:active {
  box-shadow: inset 0 0 0 #1872ab, 0 2px 0 0 #1872ab, 0 5px 3px #999999 !important;
}
button.btn-danger.dim {
  box-shadow: inset 0 0 0 #ea394c, 0 5px 0 0 #ea394c, 0 10px 5px #999999 !important;
}
button.btn-danger.dim:active {
  box-shadow: inset 0 0 0 #ea394c, 0 2px 0 0 #ea394c, 0 5px 3px #999999 !important;
}
button.dim:before {
  font-size: 50px;
  line-height: 1em;
  font-weight: normal;
  color: #fff;
  display: block;
  padding-top: 10px;
}
button.dim:active:before {
  top: 7px;
  font-size: 50px;
}
.btn:focus {
  outline: none !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}


.btn-login,
.btn-login:focus {
  background-color: #5cb85c;
  border: 1px solid transparent;
  color: #fff;
  font-size: 14px;
}

.btn-login:hover,
.btn-hover-login:hover,
.btn-hover-login:active,
.btn-hover-login.active,
.btn-login:active,
.btn-active-login.btn:active,
.btn-active-login.btn.active,
.btn-active-login+.dropdown-menu>li>a:hover,
.btn-active-login+.dropdown-menu>li>a:focus,
.btn-active-login+.dropdown-menu>.active>a,
.open .dropdown-toggle.btn.btn-active-login,
.btn-group.open .dropdown-toggle.btn.btn-active-login,
.btn-login.active,
.btn-login:disabled,
.btn-login.disabled,
.btn-login:not([class*="btn-active-"])+.dropdown-menu>li>a:hover,
.btn-login:not([class*="btn-active-"])+.dropdown-menu>li>a:focus,
.btn-login:not([class*="btn-active-"])+.dropdown-menu>.active>a,
.btn-login+.dropdown-menu:not(.head-list)>li>a.active,
.open>.dropdown-toggle.btn-success {
    background-color: #449d44 !important;
    border: 1px solid transparent;
    color: #fff !important;
}