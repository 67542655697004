.react-flow__node-custom {
    font-size: 10px;
    width: 180px;
    background: #f5f5f6;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 2px;
}

.react-flow__node-custom .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
}

.react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
    display: none;
}

.custom-node__header {
    padding: 8px 10px;
    border-bottom: 1px solid #e2e8f0;
}

.custom-node__body {
    padding: 10px;
}

.custom-node__select {
    position: relative;
    margin-bottom: 10px;
}

.custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 10px;
}

.react-flow__attribution {
    display: none;
}

.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
}

.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.react-flow .react-flow__handle {
    width: 30px;
    height: 14px;
    border-radius: 3px;
    background-color: #784be8;
}

.react-flow .react-flow__handle-top {
    top: -10px;
}

.react-flow .react-flow__handle-bottom {
    bottom: -10px;
}

.react-flow .react-flow__node {
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    font-weight: 700;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
    stroke-width: 2;
}

.wrapper {
    flex-grow: 1;
    height: 100%;
}