/* PrismJS 1.29.0
https://prismjs.com/download.html#themes=prism-okaidia&languages=markup+css+clike+javascript+ejs+markup-templating&plugins=line-highlight+line-numbers */
code[class*=language-],
pre[class*=language-] {
    color: #f8f8f2;
    background: 0 0;
    text-shadow: 0 1px rgba(0, 0, 0, .3);
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 1em;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none
}

pre[class*=language-] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border-radius: .3em
}

:not(pre)>code[class*=language-],
pre[class*=language-] {
    background: #272822
}

:not(pre)>code[class*=language-] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal
}

.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #8292a2
}

.token.punctuation {
    color: #f8f8f2
}

.token.namespace {
    opacity: .7
}

.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
    color: #f92672
}

.token.boolean,
.token.number {
    color: #ae81ff
}

.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #a6e22e
}

.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #f8f8f2
}

.token.atrule,
.token.attr-value,
.token.class-name,
.token.function {
    color: #e6db74
}

.token.keyword {
    color: #66d9ef
}

.token.important,
.token.regex {
    color: #fd971f
}

.token.bold,
.token.important {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.token.entity {
    cursor: help
}

pre[data-line] {
    position: relative;
    padding: 1em 0 1em 3em
}

.line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    padding: inherit 0;
    margin-top: 1em;
    background: hsla(24, 20%, 50%, .08);
    background: linear-gradient(to right, hsla(24, 20%, 50%, .1) 70%, hsla(24, 20%, 50%, 0));
    pointer-events: none;
    line-height: inherit;
    white-space: pre
}

@media print {
    .line-highlight {
        -webkit-print-color-adjust: exact;
        color-adjust: exact
    }
}

.line-highlight:before,
.line-highlight[data-end]:after {
    content: attr(data-start);
    position: absolute;
    top: .4em;
    left: .6em;
    min-width: 1em;
    padding: 0 .5em;
    background-color: hsla(24, 20%, 50%, .4);
    color: #f4f1ef;
    font: bold 65%/1.5 sans-serif;
    text-align: center;
    vertical-align: .3em;
    border-radius: 999px;
    text-shadow: none;
    box-shadow: 0 1px #fff
}

.line-highlight[data-end]:after {
    content: attr(data-end);
    top: auto;
    bottom: .4em
}

.line-numbers .line-highlight:after,
.line-numbers .line-highlight:before {
    content: none
}

pre[id].linkable-line-numbers span.line-numbers-rows {
    pointer-events: all
}

pre[id].linkable-line-numbers span.line-numbers-rows>span:before {
    cursor: pointer
}

pre[id].linkable-line-numbers span.line-numbers-rows>span:hover:before {
    background-color: rgba(128, 128, 128, .2)
}

pre[class*=language-].line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber
}

pre[class*=language-].line-numbers>code {
    position: relative;
    white-space: inherit
}

.line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em;
    letter-spacing: -1px;
    border-right: 1px solid #999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.line-numbers-rows>span {
    display: block;
    counter-increment: linenumber
}

.line-numbers-rows>span:before {
    content: counter(linenumber);
    color: #999;
    display: block;
    padding-right: .8em;
    text-align: right
}