.fh-150 {
  height: 150px;
}
.fh-200 {
  height: 200px;
}
.fh-250 {
  height: 250px;
}
.fh-300 {
  height: 300px !important;
}
.fh-350 {
  height: 350px;
}
.fh-400 {
  height: 400px;
}
.h-150 {
  min-height: 150px;
}
.h-200 {
  min-height: 200px;
}
.h-300 {
  min-height: 300px;
}
.min-h-inherit {
  min-height: inherit !important;
}

/* FULL HEIGHT */
.full-height {
  height: 100%;
}
.full-window-height {
  height: 100vh;
}
.full-min-height {
  min-height: 100vh;
}
.fh-breadcrumb {
  height: calc(100% - 196px);
  margin: 0 -15px;
  position: relative;
}
.fh-no-breadcrumb {
  height: calc(100% - 99px);
  margin: 0 -15px;
  position: relative;
}
.fh-column {
  background: #fff;
  height: 100%;
  width: 240px;
  float: left;
}