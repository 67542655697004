.sidebar-panel {
  width: 220px;
  background: #ebebed;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  height: calc(100% - 62px);
}
.sidebar-panel .feed-element img.rounded-circle {
  width: 32px;
  height: 32px;
}
.sidebar-panel .feed-element,
.sidebar-panel p {
  font-size: 12px;
}
.sidebar-panel .feed-element {
  margin-top: 20px;
  padding-bottom: 0;
}
.sidebar-panel .list-group {
  margin-bottom: 10px;
}
.sidebar-panel .list-group .list-group-item {
  padding: 5px 0;
  font-size: 12px;
  border: 0;
}
.sidebar-content .wrapper,
.wrapper.sidebar-content {
  padding-right: 230px !important;
}
.body-small .sidebar-content .wrapper,
.body-small .wrapper.sidebar-content {
  padding-right: 20px !important;
}
#right-sidebar {
  background-color: #fff;
  border-left: 1px solid #e7eaec;
  border-top: 1px solid #e7eaec;
  overflow: hidden;
  position: fixed;
  top: 60px;
  width: 260px !important;
  z-index: 1009;
  bottom: 0;
  right: -260px;
}
#right-sidebar.sidebar-open {
  right: 0;
}
#right-sidebar.sidebar-open.sidebar-top {
  top: 0;
  border-top: none;
}
.sidebar-container ul.nav-tabs {
  border: none;
}
.sidebar-container ul.nav-tabs.navs-4 li {
  width: 25%;
}
.sidebar-container ul.nav-tabs.navs-3 li {
  width: 33.3333%;
}
.sidebar-container ul.nav-tabs.navs-2 li {
  width: 50%;
}
.sidebar-container ul.nav-tabs li {
  border: none;
}
.sidebar-container ul.nav-tabs li a {
  border: none;
  padding: 12px 10px;
  margin: 0;
  border-radius: 0;
  background: #2f4050;
  color: #fff;
  text-align: center;
  border-right: 1px solid #334556;
}
.sidebar-container ul.nav-tabs li.active a {
  border: none;
  background: #f9f9f9;
  color: #676a6c;
  font-weight: bold;
}
.sidebar-container .nav-tabs > li.active > a:hover,
.sidebar-container .nav-tabs > li.active > a:focus {
  border: none;
}
.sidebar-container ul.sidebar-list {
  margin: 0;
  padding: 0;
}
.sidebar-container ul.sidebar-list li {
  border-bottom: 1px solid #e7eaec;
  padding: 15px 20px;
  list-style: none;
  font-size: 12px;
}
.sidebar-container .sidebar-message:nth-child(2n+2) {
  background: #f9f9f9;
}
.sidebar-container ul.sidebar-list li a {
  text-decoration: none;
  color: inherit;
}
.sidebar-container .sidebar-content {
  padding: 15px 20px;
  font-size: 12px;
}
.sidebar-container .sidebar-title {
  background: #f9f9f9;
  padding: 20px;
  border-bottom: 1px solid #e7eaec;
}
.sidebar-container .sidebar-title h3 {
  margin-bottom: 3px;
  padding-left: 2px;
}
.sidebar-container .tab-content h4 {
  margin-bottom: 5px;
}
.sidebar-container .sidebar-message > a > .float-left {
  margin-right: 10px;
}
.sidebar-container .sidebar-message > a {
  text-decoration: none;
  color: inherit;
}
.sidebar-container .sidebar-message {
  padding: 15px 20px;
}
.sidebar-container .sidebar-message .media-body {
  display: block;
  width: auto;
}
.sidebar-container .sidebar-message .message-avatar {
  height: 38px;
  width: 38px;
  border-radius: 50%;
}
.sidebar-container .setings-item {
  padding: 15px 20px;
  border-bottom: 1px solid #e7eaec;
}
/* Show profile dropdown on fixed sidebar */
body.mini-navbar.fixed-sidebar .profile-element,
.block {
  display: block !important;
}
body.mini-navbar.fixed-sidebar .nav-header {
  padding: 33px 25px;
}
body.mini-navbar.fixed-sidebar .logo-element {
  display: none;
}