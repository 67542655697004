.tablegrids {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-template-areas: 
    "options1 options2 options3"
    "datatable datatable datatable"
    "footer footer footer";
    column-gap: 5px;
    row-gap: 10px;
    justify-items: stretch;
    align-items: start;
    place-items: baseline;
    justify-content: start;
    align-content: start;  
    grid-auto-flow: row;
}

.options-inline {
    display: inline-grid;
    grid-row-gap: 5px;
    grid-auto-flow: column;
}

.options1,
.options2,
.options3 {
    display: inline-grid;
}

.options1 {
    grid-area: options1;
    justify-self: start;
}

.options2 {
    grid-area: options2;
    justify-self: center;
}

.options3 {
    grid-area: options3;
    justify-self: end;
}

#datatable {
    grid-area: datatable;
}

@media screen and (max-width:1576px) {
    .tablegrids {
        grid-template-columns: 1.25fr auto;
        grid-template-areas: 
        "options1 options2"
        "options3 options3"
        "datatable datatable"
        "footer footer";
    }
}

@media screen and (max-width:1200px) {
    .tablegrids {
        grid-template-columns: 1.9fr auto 2fr;
        grid-template-areas: 
        "options1 options2 options3"
        "datatable datatable datatable"
        "footer footer footer";
    }
}

@media screen and (max-width:978px) {
    .tablegrids {
        grid-template-columns: 1.25fr auto;
        grid-template-areas: 
        "options1 options2"
        "options3 options3"
        "datatable datatable"
        "footer footer";
    }
}

@media screen and (max-width:576px) {
    .tablegrids {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
        "options1"
        "options2"
        "options3"
        "datatable"
        "footer";
    }

    .options1,
    .options2,
    .options3 {
        justify-self: center;
    }

}
