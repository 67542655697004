.b-r-xs {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.b-r-sm {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.b-r-md {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.b-r-lg {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.b-r-xl {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}
.no-borders {
  border: none !important;
}
.no-top-border {
  border-top: 0 !important;
}
.border-left-right {
  border-left: 1px solid #e7eaec;
  border-right: 1px solid #e7eaec;
}
.border-top-bottom {
  border-top: 1px solid #e7eaec;
  border-bottom: 1px solid #e7eaec;
}
.border-left {
  border-left: 1px solid #e7eaec;
}
.border-right {
  border-right: 1px solid #e7eaec;
}
.border-top {
  border-top: 1px solid #e7eaec;
}
.border-bottom {
  border-bottom: 1px solid #e7eaec;
}
.border-size-sm {
  border-width: 3px;
}
.border-size-md {
  border-width: 6px;
}
.border-size-lg {
  border-width: 9px;
}
.border-size-xl {
  border-width: 12px;
}
.border-bottom {
  border-bottom: 1px solid #e7eaec !important;
}
.b-r {
  border-right: 1px solid #e7eaec;
}
.border-danger {
  border-color: #ed5565;
}
.border-gray {
  border-color: #c7c7c7;
}