header {
  background-color: var(--paper);
  -webkit-transition: background-color .4s;
  transition: background-color .4s;
  border-bottom: 1px solid var(--paper-highlight);
  -webkit-box-shadow: 0 1px 0 hsl(var(--ink-hsl)/0.07);
  box-shadow: 0 1px 0 hsl(var(--ink-hsl)/0.07);
  // position: fixed;
  // top: var(--wp-admin--admin-bar--height, 0);
  // left: 0;
  // width: 100%;
  z-index: 1;
}

.nav > li > a {
  color: var(--textcolor1);
  font-weight: 600;
  padding: 14px 20px 14px 25px;
  display: block;
}

.overlay {
  height: 100%;
  width: 100%;
  display: none;
  opacity: 0;
  position: fixed;
  z-index: 100; // Because this needs to be the top most
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  -webkit-transition: opacity 1000ms, visibility 1000ms, display 500ms;
  transition: opacity 1000ms, visibility 1000ms, display 500ms;
}

.overlay.true {
  display: block;
  opacity: 1;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 1rem;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.nav.metismenu > li {
  display: block;
  width: 100%;
  position: relative;
}
.nav.metismenu .dropdown-menu > li > a {
  padding: 3px 20px;
  display: block;
}
.text-clear {
  color: var(--textcolor1)
}

// .nav.navbar-right > li > a {
//   color: #999c9e;
// }
.nav > li.active > a {
  color: #ffffff;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus {
  background-color: #293846;
  color: white;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background: #fff;
}
.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  background-color: transparent;
}
.nav > li > a i {
  margin-right: 6px;
}
.navbar {
  border: 0;
}
.navbar-default {
  background-color: transparent;
  border-color: #2f4050;
}
.navbar-top-links li {
  display: inline-block;
  align-self: center;
}
.body-small .navbar-top-links li:last-child {
  margin-right: 0;
}
.navbar-top-links li a {
  padding: 20px 10px;
  min-height: 50px;
}
.dropdown-menu {
  border: medium none;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
  // max-height: 350px;
  overflow-y: auto;
}
.dropdown-menu-lg {
    width: 30vw;
}
.dropdown-menu > li > a {
  border-radius: 3px;
  color: inherit;
  line-height: 25px;
  margin: 4px;
  text-align: left;
  font-weight: normal;
  display: block;
  padding: 3px 20px;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  color: #262626;
  text-decoration: none;
  // background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #2c4869;
  outline: 0;
}
.dropdown-menu > li > a.font-bold {
  font-weight: 600;
}
.navbar-top-links .dropdown-menu li {
  display: block;
}
.navbar-top-links .dropdown-menu li:last-child {
  margin-right: 0;
}
.navbar-top-links .dropdown-menu li a {
  padding: 3px 20px;
  min-height: 0;
}
.navbar-top-links .dropdown-menu li a div {
  white-space: normal;
}
.navbar-top-links .dropdown-messages,
.navbar-top-links .dropdown-tasks,
.navbar-top-links .dropdown-alerts {
  width: 310px;
  min-width: 0;
}
.navbar-top-links .dropdown-messages {
  margin-left: 5px;
}
.navbar-top-links .dropdown-tasks {
  margin-left: -59px;
}
.navbar-top-links .dropdown-alerts {
  margin-left: -123px;
}
.navbar-top-links .dropdown-user {
  right: 0;
  left: auto;
}
.dropdown-messages,
.dropdown-alerts {
  padding: 10px 10px 10px 10px;
}
.dropdown-messages li a,
.dropdown-alerts li a {
  font-size: 12px;
}
.dropdown-messages li em,
.dropdown-alerts li em {
  font-size: 10px;
}
.nav.navbar-top-links .dropdown-alerts a {
  font-size: 12px;
}
.nav-header {
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 10px;
  background-color: #2f4050;
  // background-image: url("patterns/header-profile.png");
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.profile-element .dropdown-toggle::after {
  display: none;
}
.pace-done .nav-header {
  transition: all 0.4s;
}
ul.nav-second-level {
  background: #293846;
}
.nav > li.active {
  border-left: 4px solid #19aa8d;
  background: #293846;
}
.nav.nav-second-level > li.active {
  border: none;
}
.nav.nav-second-level.collapse[style] {
  height: auto !important;
}
.nav-header a {
  color: #DFE4ED;
}
.nav-header .text-muted {
  color: #8095a8 !important;
}
.hamburger {
  padding: 4px 12px;
  margin: 14px 5px 5px 20px;
  font-size: 14px;
  float: left;
}
.navbar-form-custom {
  float: left;
  height: 50px;
  padding: 0;
  width: 200px;
  display: block;
}
.navbar-form-custom .form-group {
  margin-bottom: 0;
}
.nav.navbar-top-links a {
  font-size: 14px;
}
.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 14px;
  height: 60px;
  margin: 0;
  z-index: 2000;
}

.navbar.navbar-static-top {
  display: flex;
  padding: 0;
  width: 100%;
  align-items: inherit;
  position: fixed;
  position: sticky;
  top: 0;
  line-height: 0;
  z-index: 10;
  margin-top: 0;
  -webkit-transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
  -o-transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.navbar-static-top .dropdown-menu {
  right: 0;
  left: auto;
}
.count-info .label {
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 6px;
  top: 12px;
}

.fa.arrow:before {
  content: "\f104";
}
.active > a > .fa.arrow:before {
  content: "\f107";
}
.nav-second-level li,
.nav-third-level li {
  border-bottom: none !important;
}
.nav.nav-third-level > li.active {
  border: none;
}
.nav-second-level li a {
  padding: 7px 10px 7px 10px;
  padding-left: 52px;
}
.fixed-sidebar.mini-navbar .nav-second-level.collapsing li a,
.nav-second-level.collapsing li a {
  min-width: 220px;
}
.body-small .nav-second-level.collapsing li a,
.mini-navbar .nav-second-level.collapsing li a {
  min-width: 140px;
}
.nav-third-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li .nav-third-level li a {
  padding-left: 62px;
}
.nav-second-level li:last-child {
  padding-bottom: 10px;
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 160px;
  height: auto;
}
body.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
.fixed-nav .slimScrollDiv #side-menu {
  padding-bottom: 60px;
}
.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.mini-navbar .nav .nav-second-level {
  position: absolute;
  left: 70px;
  top: 0;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px;
}
.canvas-menu.mini-navbar .nav-second-level {
  background: #293846;
}
.mini-navbar li.active .nav-second-level {
  left: 65px;
}
.logo-element {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
  display: none;
  padding: 18px 0;
}
.navbar-static-side {
  transition: width 0s;
}
.footer {
  transition: margin 0s;
}
.pace-done .navbar-static-side,
.pace-done .nav-header,
.pace-done li.active,
.pace-done #page-wrapper,
.pace-done .footer {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.navbar-fixed-top {
  background: #fff;
  transition-duration: 0.4s;
  border-bottom: 1px solid #e7eaec !important;
  z-index: 2030;
  position: fixed;
  right: 0;
  left: 0;
  padding: 0;
  top: 0;
}
.navbar-fixed-top .navbar-form-custom .form-control {
  height: 50px;
}
.navbar-fixed-top,
.navbar-static-top {
  background: #f3f3f4;
}
.fixed-nav #wrapper {
  margin-top: 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: none;
  border-color: #dddddd #dddddd rgba(0, 0, 0, 0);
  border-bottom: #f3f3f4;
  border-image: none;
  border-style: solid;
  border-width: 1px;
  color: #555555;
  cursor: default;
}
.nav.nav-tabs li {
  background: none;
  border: none;
}
body.fixed-nav #wrapper .navbar-static-side,
body.fixed-nav #wrapper #page-wrapper {
  margin-top: 60px;
}
body.top-navigation.fixed-nav #wrapper #page-wrapper {
  margin-top: 0;
}
body.fixed-nav.fixed-nav-basic .navbar-fixed-top {
  left: 220px;
}
body.fixed-nav.fixed-nav-basic.mini-navbar .navbar-fixed-top {
  left: 70px;
}
body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar .navbar-fixed-top {
  left: 0;
}
body.fixed-nav.fixed-nav-basic #wrapper .navbar-static-side {
  margin-top: 0;
}
body.fixed-nav.fixed-nav-basic.body-small .navbar-fixed-top {
  left: 0;
}
body.fixed-nav.fixed-nav-basic.fixed-sidebar.mini-navbar.body-small .navbar-fixed-top {
  left: 220px;
}
.fixed-nav .minimalize-styl-2 {
  margin: 10px 5px 5px 15px;
}
.body-small .navbar-fixed-top {
  margin-left: 0;
}
body.mini-navbar .navbar-static-side {
  width: 70px;
}
body.mini-navbar .profile-element,
body.mini-navbar .nav-label,
body.mini-navbar .navbar-default .nav li a span {
  display: none;
}
body.canvas-menu .profile-element {
  display: block;
}
body:not(.fixed-sidebar):not(.canvas-menu).mini-navbar .nav-second-level {
  display: none;
}
body.mini-navbar .navbar-default .nav > li > a {
  font-size: 16px;
}
body.mini-navbar .logo-element {
  display: block;
}
body.canvas-menu .logo-element {
  display: none;
}
body.mini-navbar .nav-header {
  padding: 0;
  background-color: #2c4869;
}
body.canvas-menu .nav-header {
  padding: 33px 25px;
}
body.canvas-menu .sidebar-collapse li {
  width: 100%;
}
body.mini-navbar #page-wrapper {
  width: calc(100% - 70px);
}
body.canvas-menu.mini-navbar #page-wrapper,
body.canvas-menu.mini-navbar .footer {
  margin: 0;
  width: 100%;
}
body.fixed-sidebar .navbar-static-side,
body.canvas-menu .navbar-static-side {
  width: 220px;
  z-index: 2001;
  height: 100vh;
  position: fixed;
}
body.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 0;
}
body.fixed-sidebar #page-wrapper {
  margin: 0 0 0 220px;
}
body.fixed-sidebar.body-small #page-wrapper {
  margin: 0;
}
body.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 0;
  width: 100%;
}
body.body-small.fixed-sidebar.mini-navbar #page-wrapper {
  margin: 0 0 0 220px;
}
body.body-small.fixed-sidebar.mini-navbar .navbar-static-side {
  width: 220px;
}
.fixed-sidebar.mini-navbar .nav li:focus > .nav-second-level,
.canvas-menu.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  height: auto;
}
body.fixed-sidebar.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 12px;
  border-radius: 3px;
}
body.canvas-menu.mini-navbar .navbar-default .nav > li > .nav-second-level li a {
  font-size: 13px;
  border-radius: 3px;
}
.fixed-sidebar.mini-navbar .nav-second-level li a,
.canvas-menu.mini-navbar .nav-second-level li a {
  padding: 10px 10px 10px 15px;
}
.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
  position: relative;
  padding: 0;
  font-size: 13px;
}
.fixed-sidebar.mini-navbar li.active .nav-second-level,
.canvas-menu.mini-navbar li.active .nav-second-level {
  left: 0;
}
body.fixed-sidebar.mini-navbar .navbar-default .nav > li > a,
body.canvas-menu.mini-navbar .navbar-default .nav > li > a {
  font-size: 13px;
}
body.fixed-sidebar.mini-navbar .nav-label,
body.fixed-sidebar.mini-navbar .navbar-default .nav li a span,
body.canvas-menu.mini-navbar .nav-label,
body.canvas-menu.mini-navbar .navbar-default .nav li a span {
  display: inline;
}
body.canvas-menu.mini-navbar .navbar-default .nav li .profile-element a span {
  display: block;
}
.canvas-menu.mini-navbar .nav-second-level li a,
.fixed-sidebar.mini-navbar .nav-second-level li a {
  padding: 7px 10px 7px 52px;
}
.fixed-sidebar.mini-navbar .nav-second-level,
.canvas-menu.mini-navbar .nav-second-level {
  left: 0;
}
body.canvas-menu nav.navbar-static-side {
  z-index: 2001;
  background: #2f4050;
  height: 100%;
  position: fixed;
  display: none;
}
body.canvas-menu.mini-navbar nav.navbar-static-side {
  display: block;
  width: 220px;
}
.top-navigation #page-wrapper {
  width: 100%;
}
.top-navigation .navbar-nav .dropdown-menu > .active > a {
  background: white;
  color: #2c4869;
  font-weight: bold;
}
.white-bg .navbar-fixed-top,
.white-bg .navbar-static-top {
  background: #fff;
}
.top-navigation .navbar {
  margin-bottom: 0;
}
.top-navigation .nav > li > a {
  padding: 15px 20px;
  color: #676a6c;
}
.top-navigation .nav > li a:hover,
.top-navigation .nav > li a:focus {
  background: #fff;
  color: #2c4869;
}
.top-navigation .navbar .nav > li.active {
  background: #fff;
  border: none;
}
.top-navigation .nav > li.active > a {
  color: #2c4869;
}
.top-navigation .navbar-right {
  margin-right: 10px;
}
.top-navigation .navbar-nav .dropdown-menu {
  box-shadow: none;
  border: 1px solid #e7eaec;
}
.top-navigation .dropdown-menu > li > a {
  margin: 0;
  padding: 7px 20px;
}
.navbar .dropdown-menu {
  margin-top: 0;
}
.top-navigation .navbar-brand {
  background: #2c4869;
  color: #fff;
  padding: 15px 25px;
  font-size: 18px;
  line-height: 20px;
}
.top-navigation .navbar-top-links li:last-child {
  margin-right: 0;
}
.top-navigation.mini-navbar #page-wrapper,
.top-navigation.body-small.fixed-sidebar.mini-navbar #page-wrapper,
.mini-navbar .top-navigation #page-wrapper,
.body-small.fixed-sidebar.mini-navbar .top-navigation #page-wrapper,
.canvas-menu #page-wrapper {
  margin: 0;
  width: 100%;
}
.top-navigation.fixed-nav #wrapper,
.fixed-nav #wrapper.top-navigation {
  margin-top: 50px;
}
.top-navigation .footer.fixed {
  margin-left: 0 !important;
}
.top-navigation .wrapper.wrapper-content {
  padding: 40px;
}
.top-navigation.body-small .wrapper.wrapper-content,
.body-small .top-navigation .wrapper.wrapper-content {
  padding: 40px 0 40px 0;
}
.navbar-toggler {
  background-color: #2c4869;
  color: #fff;
  padding: 6px 12px;
  font-size: 14px;
  margin: 8px;
}
.top-navigation .navbar-nav .open .dropdown-menu > li > a,
.top-navigation .navbar-nav .open .dropdown-menu .dropdown-header {
  padding: 10px 15px 10px 20px;
}
@media (max-width: 768px) {
  .top-navigation .navbar-header {
    display: block;
    float: none;
  }
}
.menu-visible-lg,
.menu-visible-md {
  display: none !important;
}
@media (min-width: 1200px) {
  .menu-visible-lg {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .menu-visible-md {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .menu-visible-md {
    display: block !important;
  }
  .menu-visible-lg {
    display: block !important;
  }
}
.nav.navbar-top-links .link-block a {
  font-size: 12px;
}
.navbar-top-links {
  text-align: right;
}


.nav-tabs > li > a {
  color: #A7B1C2;
  font-weight: 600;
  padding: 10px 20px 10px 25px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #676a6c;
}