.table > caption + thead > tr:first-child > td,
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > td,
.table > thead:first-child > tr:first-child > th {
  border-top: 0;
}
.table-bordered-head, .table-bordered {
  border: 1px solid #EBEBEB;
}

.table-bordered-head > thead > tr > th,
.table-bordered-head > thead > tr > td {
  background-color: #F5F5F6;
  border: 1px solid #e7e7e7;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  background-color: #F5F5F6;
  border-bottom-width: 1px;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #e7e7e7;
}
.table > thead > tr > th {
  border-bottom: 1px solid #DDDDDD;
  vertical-align: bottom;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #e7eaec;
  line-height: 1.42857;
  padding: 8px;
  vertical-align: top;
}
.table {
  color: #676a6c;
}


// .sticky-table-row:nth-of-type(odd) .sticky-table-cell {
//   background-color: rgba(255, 255, 255, 0.05) !important;
// }

.sticky-table-row:nth-of-type(odd) .sticky-table-cell {
  // background-color: rgba(0, 0, 0, 0.05)  !important;
  background-color: #F2F2F2  !important;
}

.sticky-table-cell {
  border: 1px solid #dee2e6 !important;
  padding: 0.75rem !important;
  vertical-align: top !important;
}

thead .sticky-table-cell {
  vertical-align: bottom !important;
  border-bottom-width: 1px !important;

  border: 1px solid #e7e7e7;
  background-color: #F5F5F6;
}