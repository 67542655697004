/* ISSUE TRACKER */
.issue-tracker .btn-link {
  color: #2c4869;
}
table.issue-tracker tbody tr td {
  vertical-align: middle;
  height: 50px;
}
.issue-info {
  width: 50%;
}
.issue-info a {
  font-weight: 600;
  color: #676a6c;
}
.issue-info small {
  display: block;
}
