/* WRAPPERS */
#wrapper {
  width: 100%;
  overflow-x: hidden;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}
.wrapper {
  padding: 0 20px;
}
.wrapper-content {
  padding: 20px 10px 40px;
}
#page-wrapper {
  // padding: 0 15px;
  position: relative !important;
  flex-shrink: 1;
  width: 100%;
}
@media (min-width: 768px) {
  #page-wrapper {
    position: inherit;
  }
}
.title-action {
  text-align: right;
  padding-top: 30px;
}
.ibox-content h1,
.ibox-content h2,
.ibox-content h3,
.ibox-content h4,
.ibox-content h5,
.ibox-title h1,
.ibox-title h2,
.ibox-title h3,
.ibox-title h4,
.ibox-title h5 {
  margin-top: 5px;
}
ul.unstyled,
ol.unstyled {
  list-style: none outside none;
  margin-left: 0;
}
ul.styled,
ol.styled {
  list-style: inherit;
}
.big-icon {
  font-size: 160px !important;
  color: #e5e6e7;
}
#page-wrapper {
  min-height: 100vh;
}