#brand-logo-white, #brand-logo {
    font-family: 'Montserrat', sans-serif; 
    text-transform: uppercase; 
    font-weight: 400;
}

#brand-logo {
    color: #323A45 !important;
}

#brand-logo-white {
    color: #fff !important;
}

.navbar-header > #brand-logo-mobile {
    display: none;
}

.navbar-header > #brand-logo-dark {
    color: #323A45;
    display: block;
}

@media (max-width: 768px) {
    .navbar-header > #brand-logo-mobile {
        display: block;
    }
}