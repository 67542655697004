.label {
    border-radius: 3px;
    font-weight: 600;
    font-size: 10px;
    padding: .2em .6em .3em;
}

.label-sm {
    font-size: 9.5px;
}

.label:empty {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: sub
}

.label.label-fw {
    margin-right: 0.5em
}

.labels .label {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 3px
}

.label-md {
    font-size: 100%
}

.label-table {
    display: inline-block;
    width: 80%;
    min-width: 8ex;
    font-size: 1em;
    max-width: 100px;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top
}

.label-default {
    background-color: #e3e8ee;
    color: #333
}

.label-default[href]:hover,
.label-default[href]:focus {
    background-color: #d3dbe4;
    color: #FFFFFF;
}

.label-primary {
    background-color: #25476a;
    color: #FFFFFF;
}

.label-primary[href]:hover,
.label-primary[href]:focus {
    background-color: #1e3a57;
    color: #FFFFFF;
}

.label-info {
    background-color: #03a9f4;
    color: #FFFFFF;
}

.label-info[href]:hover,
.label-info[href]:focus {
    background-color: #0398db;
    color: #FFFFFF;
}

.label-live {
    background-color: var(--live);
    color: #FFFFFF;
}

.label-live[href]:hover,
.label-live[href]:focus {
    background-color: var(--livehover);
    color: #FFFFFF;
}

.label-draft {
    background-color: var(--draft);
    color: #FFFFFF;
}

.label-draft[href]:hover,
.label-draft[href]:focus {
    background-color: var(--drafthover);
    color: #FFFFFF;
}

.label-danger {
    background-color: #f44336;
    color: #FFFFFF;
}

.label-danger[href]:hover,
.label-danger[href]:focus {
    background-color: #f32c1e;
    color: #FFFFFF;
}

.label-mint {
    background-color: #26a69a;
    color: #FFFFFF;
}

.label-mint[href]:hover,
.label-mint[href]:focus {
    background-color: #219187;
    color: #FFFFFF;
}

.label-purple {
    background-color: #ab47bc;
    color: #FFFFFF;
}

.label-purple[href]:hover,
.label-purple[href]:focus {
    background-color: #9b3eab;
    color: #FFFFFF;
}

.label-pink {
    background-color: #f06292;
    color: #FFFFFF;
}

.label-pink[href]:hover,
.label-pink[href]:focus {
    background-color: #ee4b82;
    color: #FFFFFF;
}

.label-dark {
    background-color: #3a444e;
    color: #FFFFFF;
}

.label-dark[href]:hover,
.label-dark[href]:focus {
    background-color: #2f373f;
    color: #FFFFFF;
}