/* BACKGROUNDS */
.gray-bg,
.bg-muted {
  background-color: var(--bgcolour1);
}

.default-bg {
  background-color: var(--bgcolour2);
}

.light-bg {
  background-color: var(--bgcolour1);
}

.white-bg {
  background-color: #ffffff;
}
.black-bg {
  background-color: #262626;
}
.blue-bg,
.bg-success {
  background-color: var(--blue) !important;
  color: #ffffff;
}
.navy-bg,
.bg-primary {
  background-color: var(--primary) !important;
  color: #ffffff;
}
.lazur-bg,
.bg-info {
  background-color: #23c6c8 !important;
  color: #ffffff;
}
.yellow-bg,
.bg-warning {
  background-color: var(--warning) !important;
  color: #ffffff;
}
.red-bg,
.bg-danger {
  background-color: var(--danger) !important;
  color: #ffffff;
}

.bg-none {
  background: none !important;
}