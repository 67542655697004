/* COLORS */
.text-navy {
  color: #1ab394 !important;
}
// .text-primary {
//   color: inherit !important;
// }
// .text-success {
//   color: #1c84c6 !important;
// }
// .text-info {
//   color: #23c6c8 !important;
// }
// .text-warning {
//   color: #f8ac59 !important;
// }
// .text-danger {
//   color: #ed5565 !important;
// }
// .text-muted {
//   color: #888888 !important;
// }
.text-white {
  color: #ffffff;
}
.text-inherit {
  color: inherit;
}

.text-inherit:hover {
  color: inherit;
}

.text-highlighted {
  background-color: yellow; 
  padding: 0.1em 0.2em;
}