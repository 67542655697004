.card {
    background-color: var(--bgcard1);
    // box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .4);
}

@media (prefers-color-scheme: dark) {
    :root:not([data-theme='light']) {
        box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 1);
    }
}

.card:hover,
.card:active,
.card:focus {
    background-color: var(--bgcard2);
}